/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Andri Samsuri
==============================================================================================*/
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,700,800);
@import "../css/reset";
@import "../css/slick-theme";
@import "../css/slick";
@import "../css/jquery.bxslider";
@import "sass-lib";

html {
    overflow: auto;   
}
body {  
    color: #333;
    font-size: 12px;
    font-family: "Lato"; 
    position: relative;
    overflow-x: hidden;
    overflow: auto;
}
img{ border:0; max-width:100%; height:auto; }  
p {
    margin: 1px 0 15px;
    line-height: 20px;
}
input, textarea, select {
    font-size: 12px;
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: 12px;
    font-family: "Lato";   
}

input[type=checkbox], input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}
h1 { font-size: 32px; }
h2 { font-size: 28px; }
h3 { font-size: 24px; }
h4 { font-size: 20px; } 
h5 { font-size: 16px; } 
h6 { font-size: 14px; }

h1,
h2,
h3,
h4, 
h5, 
h6 {color: #111;}


/* structure 
----------------------------------------------------------------------------------------------*/
section { position: relative; }
.wrapper,
.container {width: 1500px; margin: 0 auto; position: relative;}
.container.cv2 {width: 1170px;
                .aDes h2{font-size: 32px}
                p{font-size: 16px;line-height: 28px;}

}



/* global
----------------------------------------------------------------------------------------------*/
.after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
.before_clear:before{clear:both;display: block;content:" "}
.a-left{text-align: left}
.a-right{text-align: right}
.a-center{text-align: center}
.italic { font-style: italic; }
.bold { font-weight: bold; }
.regular { font-weight: lighter; }
.fl { float: left; }
.fr { float: right; }
.clear { clear: both; }


.clearfix {
    *zoom: 1;
}
.clearfix:before,
.clearfix:after {
    display: table;
    line-height: 0;
    content: "";
}
.clearfix:after {
    clear: both;
}


a {@include transition(all .15s ease-in-out);}
hr {height: 1px; border:none; background: #d8d8d8; margin:22px 0;}          

a.btn_std {
    display: inline-block; padding: 0 23px; height: 40px; line-height: 40px; background:#f58220; color: #fff; 
    @include borderRadius(30px); @include transition(all .15s ease-in-out); color: #fff; font-size: 14px;  
    font-weight: bold; text-transform: uppercase;    
    &:hover {
        background:#1675a1; color: #fff;     
    }
    &.v2 {
        padding: 0 38px; 
    }
    &.v3 {
        padding: 0 38px; background:#389fcf;     
        &:hover {background:#f58220;}   
    }
    &.white {  
        height: 50px; line-height: 50px;  
        padding: 0 55px; background:#fff; color: #f58220;       
        &:hover {background:#f58220; color: #fff;}
    }
}


/* header
----------------------------------------------------------------------------------------------*/
header {
    height: 80px; position: fixed; width: 100%; top: 0; left: 0; z-index: 898989; border-bottom: 1px solid #fff;  
    background:#fff; 
    &.line {border-bottom: 1px solid #ddd;} 
    .logo {
        float: left; width: 165px; height: 44px; margin: 20px 0 0 0; 
        a {display: block; height: 100%; text-indent: -999999px;}   
    }
    .fr {margin-top: 20px;}
    .nav {
        float: left;
        ul {
            li {
                display: inline-block; 
                a {
                    display: block; padding: 15px 11px; height: 31px; font-size: 14px; color: #111; text-transform: uppercase; 
                    font-weight: bold;  position: relative;
                    &:after {
                        position:absolute; width: 0px; height: 6px; background: #f58220; content: "";
                        @include borderRadius(5px); bottom: -3px; left: 50%; @include transform(translateX(-50%));
                        @include transition(all .15s ease-in-out);   
                    }
                    &:hover,
                        &.active { 
                        &:after {width:40px;}   
                    }      
                }
                &.special {
                    a {
                        color:#f58220;
                        &:after {display:none;} 
                    }
                }
            } 
        }
    }
    .blockLanguage {
        float: left; padding: 12px 5px 15px 15px;    
        .wSelect {
            color: #999; text-transform: uppercase; font-size: 14px; font-weight: bold;
            position: relative; padding-right: 0; cursor: pointer;  
            &:after {
                position:absolute; width: 5px; height: 4px;  content: ""; top: 8px; right: 0;
                background:url('../images/material/arr_small.png') no-repeat 0 0;  
            }
            select {
                border:0; padding: 0 10px 0 10px; font-size: 14px; font-weight: bold; cursor: pointer;
                position: relative; z-index: 3; background: none;    
            } 
        } 
    } 
    .wfr {float: right; margin-left: 160px;}
    .col_need {float: right; margin: 0 0 0 0;}   
    .navSearch {
        float: right; width: 22px; height: 23px; text-indent: -999999px; 
        background: url('../images/material/ico_search.png') no-repeat 0 0; 
        background-size: 100%; cursor: pointer; margin: 10px 30px 0 0;    
    }   

    .blockSearch {
        border-top: 1px solid #f58220; background: #fff; height: 78px;  
        position: absolute; width: 100%; 
        display: none;   
        /*@include transition(all .2s ease-in-out); */
        .box {
            margin: 15px 190px; position: relative;  
            input[type='submit'] {
                position: absolute; width: 32px; height: 32px; top: 5px; left: 0; padding: 0; border:0;    
                background: url('../images/material/ico_search_v2.png') no-repeat 0 0; 
                cursor: pointer;  
            }
            input[type='text'] { 
                width: 100%; font-size: 32px; line-height: 32px; font-weight: 300; font-style: italic; color: #ccc;  
                padding: 0 65px; border:0;
                @include boxSizing(border-box);   
            }
        }
    }         

    .toggle_menu {
        display: none; position: absolute; top: 0; right: 0; cursor: pointer;  
        width: 80px; height: 80px; background:#f58220;  z-index: 858585;        
        .in {
            position: relative; height: 100%; width: 100%; 
        }
        span {
            position: absolute; width: 34px; height: 4px; background: #fff; display: block; text-indent: -999999px;
            left: 23px;
            @include borderRadius(3px);
            @include transform(rotate(0deg)); 
            @include transition( .25s ease-in-out);  

            &:nth-child(1) { 
                top: 29px;
                @include transformorigin(left center);  
            }
            &:nth-child(2) {
                top: 40px;
                @include transformorigin(left center);
            }
            &:nth-child(3) {
                top: 51px;
                @include transformorigin(left center);
            }   
        }

        &.expand {
            /* z-index: 858585; */     
            span {
                position: absolute; width: 34px; height: 4px; background: #fff; display: block; text-indent: -999999px;
                left: 28px;  
                @include borderRadius(3px);                
                &:nth-child(1) {top: 28px; @include transform(rotate(45deg));}     
                &:nth-child(2) {top: 40px; opacity: 0;}
                &:nth-child(3) {top: 51px; @include transform(rotate(-45deg));}    
            }
        }
    }

}

.boxNavmobile {
    display: none; 
    position: fixed; width: 100%; height: 100%; top: 0; left: 0; z-index: 1555;     
    .navside {
        width: 336px; height: 100%; position: absolute; top: 0; right: -200px; z-index: 5;  
        background: #fff; 
        opacity: 0; filter: alpha(opacity=0); 
        @include boxSizing(border-box);  
        .mmtop {
            position: relative; height: 80px; border-bottom: 1px solid #e5e5e5;    
            background: #fff; z-index: 5;
        }
        .msnav {
            text-align: right; margin:0; position: absolute; right: 0; top: 0;
            overflow: auto; height: 100%; padding: 100px 45px; width: 100%;                        
            @include boxSizing(border-box);  
            ul {
                margin:0 0 28px 0; padding-bottom: 90px; border-bottom: 1px solid #e5e5e5;  
                li {
                    a {
                        display: block; padding: 13px 0 13px 0; font-size: 20px; font-weight: bold;  
                        text-transform: uppercase; color: #111; text-align: right; 
                    }
                    &.special {
                        a {color: #f58220;} 
                    }
                }
            }
        }
        .col_need {display: block; float: none;} 
    }
    .moverlay {
        position: absolute; width: 100%; height: 100%; top: 0; left: 0;  
        background:rgba(0,0,0, 0.4); z-index: 2;
    }
}



/* middle
----------------------------------------------------------------------------------------------*/
#middle {padding-top: 80px; min-height: 320px;}   
#homeSlider {
    line-height: 0;
    .inn {height: 100%; width: 100%; position: absolute; top: 0; left: 0;}     
    .container {height: 100%;}    
    img {
        position: relative; z-index: 1; width: 100%;
    }
    .slide {
        position: relative;
        .caption {
            position: absolute; left: 165px; z-index: 3; color: #fff; top: 50%; transform: translateY(-50%); 
            width: 542px;   
            h3 {
                font-size: 60px; line-height: 55px; text-transform: uppercase; margin-bottom: 28px;
                position: relative; color: #fff;
                /*top: 50px; opacity: 0; filter:alpha(opacity=0);*/
            }   
            .tdes {
                font-size: 18px; line-height: 28px; margin-bottom: 28px;
                position: relative;
            }  
            .btn_std {
                position: relative; 
            }   
        }
    } 
    .slick-dots {
        right: 34px; width:17px; height: auto; z-index: 15; bottom: 50%; transform: translateY(-50%); 
        li {
            display: block; width: 17px; height: 17px; margin: 0 0 9px 0; 
            button {
                width: 17px; height: 16px; background:url('../images/material/bullet_paging.png') no-repeat 0 0; 
                padding: 0;
                &:before {display:none;} 
            }            
            &.slick-active {
                button {background-position: 0 -16px;}       
            }
        }
    }
    .slick-dotted.slick-slider {margin-bottom: 0;}  

}

.slick-disabled {display: none !important;}  


.wrapNav {   
    position: absolute; width: 100%; bottom: 0; right: 0;  
    .fr {width: 190px; margin: 0 0;}    
    .nav_direction {
        width: 178px; position: relative; 
        button {
            width: 26px; height: 18px; display: block; position: absolute; top: 10px; 
            background: url('../images/material/sarr_next.png') no-repeat 0 0; 
            border:0; padding: 0; text-indent: -999999px; cursor: pointer;
            &.arr_prev {background: url('../images/material/sarr_prev.png') no-repeat 0 0; left: 0;}
            &.arr_next {background: url('../images/material/sarr_next.png') no-repeat 0 0; right: 0;} 
        }
    }
    .info_pager {
        width: 178px; 
        span {float: left; margin-right: 5px;} 
        .pos {margin-left: 44px; font-size: 36px; line-height: 36px;}
        .line {font-size: 36px; line-height: 36px; color: #ccc;}
        .total {font-size: 20px; line-height: 20px; color: #ccc; margin-top: 13px;}      
    }
}

.wrapArrow {    
    position: relative; width: 100%; bottom: 0; right: 0;   
    .fr {width: 190px; float: none; margin: 0 auto;}    
    .nav_direction {
        width: 178px; position: relative; 
        a {
            width: 26px; height: 18px; display: block; position: absolute; top: 10px;    
            background: url('../images/material/sarr_next.png') no-repeat 0 0; 
            border:0; padding: 0; text-indent: -999999px; cursor: pointer;
            &.arr_prev {background: url('../images/material/sarr_prev.png') no-repeat 0 0; left: 0;}
            &.arr_next {background: url('../images/material/sarr_next.png') no-repeat 0 0; right: 0;} 
        }
    }
    .info_pager {
        width: 178px; 
        span {float: left; margin-right: 5px;} 
        .pos {margin-left: 44px; font-size: 36px; line-height: 36px;}
        .line {font-size: 36px; line-height: 36px; color: #ccc;}
        .total {font-size: 20px; line-height: 20px; color: #ccc; margin-top: 13px;}     
    }
}


.homeAbout {
    /*height: 700px; */
    position: relative;  background: #fff; 
    .haSlide {height: 100%; }    
    .bg {
        position: absolute; z-index: 1; bottom: 0; left: 0; width: 100%; height: 100%;  
        background-size: cover !important; background-position: 0 bottom !important;
    }
    .inn {height: 100%; width: 100%; position: relative; top: 0; left: 0; z-index: 6; padding: 90px 0;}      
    .container {width: 1170px; height: 100%; padding: 0 0;}
    .slide {
        position: relative; height: 100%;
        .imgsl {
            float: left; position: relative; width: 490px;       
            top: 0; transform: translateY(0);   
            height: 380px;             
            img {
                position: absolute; top: 50%; max-width: none;
                /*max-width: 100%;  */
                @include transform(translateY(-50%)); 
                &.img_1 {width: 822px; left: -307px;} 
                &.img_2 {width: 490px;}
                &.img_3 {width: 737px; left: -207px;}
            }
        }
        .caption {
            position: relative; z-index: 3; color: #333; 
            /*top: 45%; transform: translateY(-45%);   */
            width: 612px; float: right;  margin-bottom: 50px;     
            h3 {
                font-size: 36px; font-weight: 300; line-height: 40px; text-transform: capitalize; margin-bottom: 28px;  
                .orange {color: #f58220;} 
            }    
            .tdes {font-size: 18px; line-height: 28px; margin-bottom: 28px;}       
        }
    } 
    .slick-list {height: 100%;}
    .slick-track {height: 100%;}  
    .slick-dots {bottom: 0; z-index: 333;} 
    .wrapNav {
        position: absolute; width: 100%; bottom: 92px; right: 0;
        .container {padding: 0;} 
        .fr {width: 612px;} 
        .nav_direction {
            width: 178px; position: relative;
            button {
                width: 26px; height: 18px; display: block; position: absolute; top: 10px; 
                background: url('../images/material/sarr_next.png') no-repeat 0 0; 
                border:0; padding: 0; text-indent: -999999px; cursor: pointer;
                &.arr_prev {background: url('../images/material/sarr_prev.png') no-repeat 0 0; left: 0;}
                &.arr_next {background: url('../images/material/sarr_next.png') no-repeat 0 0; right: 0;} 
            }
        }
        .info_pager {
            width: 178px; 
            span {float: left; margin-right: 5px;} 
            .pos {margin-left: 44px; font-size: 36px; line-height: 36px;}
            .line {font-size: 36px; line-height: 36px; color: #ccc;}
            .total {font-size: 20px; line-height: 20px; color: #ccc; margin-top: 13px;}     
        }
    }   

}

.homePartner { 
    height: 200px; background:#eaeaea url('../images/material/homePartner.jpg') no-repeat right top;  
    .container {padding: 38px 0;}   
    .img {position: absolute; top: 38px; /* left: 0; */}   
    .txt {
        margin: 8px 0 0 270px; 
        p {font-size: 18px; line-height: 28px; font-style: italic;}     
    } 
}

.homeServices {
    .container {padding: 95px 0 10px 0;}  
    .hsTop {padding: 0 174px; text-align: center; margin:0 0 35px 0;}   
    h3 {font-size: 36px; line-height: 40px; font-weight: bold; margin-bottom: 20px;}       
    .stxt {font-size: 18px; line-height: 28px; margin:0;} 
    .rows {
        .col {
            display: inline-block; position: relative; width: 350px; margin: 0 57px 0 0;
            cursor: pointer; vertical-align: top; 
            &:nth-child(3n+3) {margin-right: 0;}   
            .img {
                position: relative; width: 350px; height: 200px; @include borderRadius(6px); overflow:hidden;  
                margin: 0 0 24px 0;   
                img {@include borderRadius(6px); @include transition(all .25s ease-in-out); position: relative; z-index: 2;}  
                &:before {
                    position: absolute; width: 100%; height: 100%; background:#ebebeb; top: 10px; right: -10px;
                    content: ""; z-index: 1; @include borderRadius(6px);
                }
                &:after {
                    position: absolute; width: 94px; height: 86px; bottom: 0px; left:0px;     
                    content: ""; z-index: 3; 
                    background:url('../images/material/ribService.png') no-repeat 0 0;  
                }
            }
            .tcol {
                h4 {font-size: 20px; text-transform: uppercase; font-weight: 600; margin-bottom: 6px;}   
                .txt {font-size: 16px; color:#666;}     
            }
            &:hover {
                .img {
                    img {
                        transform: scale(1.1, 1.1);
                    }
                }
            }
        }
    } 
}


.colTab {
    .tabMenu {
        margin-bottom: 40px; 
        ul {
            text-align: center; 
            li {
                display: inline-block; position: relative; padding: 0 20px 0 0; margin: 0 20px 0 0;  
                &:after {
                    position:absolute; width: 2px; height: 32px; background: #e6e6e5; content: "";  
                    top:10px; right: 0;
                }
                &:last-child {
                    margin-right: 0;
                    &:after {display:none;} 
                }
                a {
                    height: 50px; line-height: 50px; padding: 0 28px; font-size: 16px; color: #ccc; text-transform: uppercase;
                    font-weight: 600; display: inline-block;  @include borderRadius(30px); @include transition(all .15s ease-in-out); 
                    &:hover {
                        background:#f58220; color: #fff; 
                    } 
                }
                &.active {
                    a {background:#f58220; color: #fff;} 
                }
            }
        }
    }
}

.colProduct {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    .col {
        display: inline-block; 
        width: 33.333333333333333%; 
        margin: 0 0 28px 0; 
        vertical-align: top; 
        position: relative; 
        padding: 0 15px;
        cursor: pointer;
        box-sizing: border-box; 
        &:nth-child(3n+3){margin-right: 0;}   
        .img {
            height: 200px; position: relative; @include borderRadius(6px); overflow: hidden; 
            &:before {
                position:absolute; left: 0; bottom: 0; height: 110px; width: 100%; content: ""; 
                background: url('../images/material/shadowProduct.png') repeat-x 0 0;
                @include borderRadius(10px);  z-index: 2; 
            }
            img {@include borderRadius(10px); @include transition(all .25s ease-in-out); z-index: 1;}     
        } 
        .cate {
            position: absolute; left: 0; bottom: 25px; width: 100%; color: #fff; font-size: 20px; font-weight: bold;
            text-transform: uppercase;  height: 46px; line-height: 22px; padding: 0 0 0 35px; z-index: 3;
            @include boxSizing(border-box); display: block;
            &:before {
                position:absolute; width: 50px; height: 46px; content: "";
                left: 16px; top: 50%; 
                background: url('../images/material/cate_ico.png') no-repeat 0 0;   
                @include transform(translateY(-50%)); 
            } 
            span {
                position: absolute; top: 50%;
                @include transform(translateY(-50%)); 
            }    
        }
        &:hover {
            .img {
                img {
                    transform: scale(1.1, 1.1);
                }
            }
        }
    }
}

.homeProduct {
    height: auto; padding: 1px 0 0 0; position: relative; 
    /*background:#fff url('../images/material/bgHomeProduct.png') no-repeat center top;  */
    background:#fff;   
    .img-mouseeffect {
        position: absolute; width: 100%; height: 100%;  
        img {
            position: absolute; left: 0; top: 0; width: 100%;
            &.effect_3 {top: 250px;}  
        }
    }
    .container {padding: 0 0 135px 0; margin-top: 520px;}  
    .hptop {
        padding: 0 285px 25px 285px; text-align: center; color:#fff;       
        h3 {font-size: 36px; color: #fff; padding: 0 0 25px 0;}      
        .txt {
            font-size: 18px; 
            p {line-height: 28px;} 
        }
    }
    .hpbot {
        background: #fff; min-height: 300px; @include borderRadius(7px); position: relative; 
        padding: 62px 165px;  
        &:before {
            position:absolute; width: 75px; height: 28px; top: -1px; left: 49%; content: "";  
            background: url('../images/material/hpbot_arrow.png') no-repeat 0 0;  
            transform:translateX(-49%)   
        }              
    }
}





.colHelp {
    height: 358px; background-size: cover !important; position: relative; z-index: 10;
    .container {height: 100%;} 
    .people {
        position: absolute; width: 332px; height: 535px;     
        /*left:0; */
        bottom: 0;   
    }
    .text {
        width: 530px; float: right; padding: 100px 0 0 0; 
        h3 {font-size: 36px; line-height: 40px; font-weight: 300; margin: 0 0 30px 0;}    
    }  
}


.homeNews {
    background:#f5f5f5; position: relative; z-index: 10;
    .container {padding: 66px 0 70px 0;}    
    .col {
        display: inline-block; width: 255px; margin: 0 45px 0 0; cursor: pointer; 
        vertical-align: top;   
        &:nth-child(4n+4) {margin-right:0;} 
        .inn {
            padding: 0 0 0 28px; position: relative;
            &:before {
                position:absolute; width: 39px; height: 36px; content: ""; top: -28px; left: 0; 
                background:url('../images/material/snews_ico.png') no-repeat 0 0; 
            }
            h5 {font-size: 12px; color: #f58220; font-weight:bold; margin-bottom: 5px; text-transform: uppercase;}  
            .stext {font-size: 16px; line-height: 20px; margin-bottom: 8px; height: 63px; overflow: hidden;}       
            .date {font-size: 12px; color: #999;}   
        } 
    }
}

.homeBrand {
    position: relative; z-index: 10;
    .container {padding: 65px 0 55px 0;}  
    .wsBrand {
        .cl {
            display: inline-block; vertical-align: top; margin: 0 59px 0 0;     
            &:nth-chid(7n+7) {margin-right: 0;}  
            .img {
                height: 68px; position: relative;
                img {position: relative; top: 50%; transform: translateY(-50%);}   
            } 
        }
    }
}


.breadcrumb {
    position: relative; font-weight: bold; 
    margin-bottom: 10px; 
    &:after {
        position:absolute; bottom: 0; left: 0; width: 70px; height: 1px; background: #f58220; content: ""; 
    }
    ul {
        li {
            display: inline-block; padding: 0 7px 10px 9px; position: relative; font-size: 14px; color: #fff;    
            &:first-child {padding-left:0;} 
            &:after {
                position: absolute; width: 3px; height: 3px; background: #fff; @include borderRadius(50%);   
                content: ""; top: 5px; left: 0;   
            } 
            a {
                font-size: 12px; color: #fff; display: block; 
            }
        }
    }

}

.bannerPage { 
    position: relative; overflow: hidden;   
    .img {
        line-height: 0;
        img {width: 100%;}
    }  
    &:after {
        position:absolute; width: 363px; height: 334px; right: 0; bottom: 0; content: "";
        background: url('../images/material/ribbon_banner.png') no-repeat 0 0;  
    }    
    .bacaption {
        position: absolute; width: 100%; height: 100%; top: 0; left: 0;   
        .container {height: 100%;}  
        .inn {
            position: absolute; width: 100%; height: auto; top: 50%; @include transform(translateY(-50%));
            padding: 0 252px; @include boxSizing(border-box);

            h1 {
                font-size: 40px; line-height: 40px; font-weight: bold; color: #fff; text-transform: uppercase;   
            } 
        } 

    }
}  


.subMenu {
    height: 59px; border-bottom: 1px solid #f58220; position: relative; z-index: 45; 
    /*margin-bottom: 50px;   */
    ul {
        padding: 0 100px 0 100px; 
        li {
            display: inline-block; height: auto; position: relative; 
            /*border-right: 1px solid #ccc;*/
            &:after {
                position:absolute; width: 1px; height: 30px; content: ""; top: 50%; right: 0;
                background: #ccc;    
                @include transform(translateY(-50%)); 
            }

            a {
                display: block; padding: 21px 20px 22px 20px; font-size: 16px; line-height: 16px; color: #333; font-weight: bold;  
                position: relative;                
                &.active {
                    color:#f58220;  
                } 
            }            
            &.hasChild {                 
                a {
                    padding-right: 30px; 
                    &:after {
                        position:absolute; width: 5px; height: 4px; content: ""; top: 50%; right: 15px;    
                        background: url('../images/material/arr_small.png') no-repeat 0 0;  
                    }
                }                
            }
            &:last-child {
                border:none;  
            }

            &:hover {
                background: #f58220;
                a {color: #fff;} 
                &:after {background: #f58220;}
                &.hasChild {
                    a {
                        &:after {background: url('../images/material/arr_small_fff.png') no-repeat 0 0;}
                    } 
                }

            }

            /* &:hover {
                a {color:#f58220;}
            } */
            .scnav {
                position: absolute; width: 250px; background: #f58221; left: 0px; top: 100%;  
                display: none; z-index: 15; 
                @include borderRadius(0 0 5px 5px);   
                ul {
                    padding: 10px 14px 20px 14px;  
                    li {
                        display: block; border-right:none; border-bottom: 1px solid #b86119; height: auto; 
                        margin-bottom: 0; padding-bottom: 0; 
                        &:after {display:none;} 
                        a {
                            display: block; font-size: 13px; color: #fff; padding: 15px 0;  
                            &:after {display:none;} 
                        }
                        &:last-child { 
                            border-bottom: 0; 
                            a {padding-bottom: 0; margin-bottom: 10px; border-bottom: 0;} 
                        }     
                    }
                }
            }
        }
    }
    &.no-border {border: 0;}
    &.schild {
        z-index: 3; height: 40px; background: #f58220; border:0; border-top: 0;
        margin-top: 0; 
        .container {height: 100%;} 
        ul {            
            li {
                margin-right: -2px;  
                a { 
                    color: #fff; font-size: 13px; padding: 12px; position: relative; 
                    height: 40px; 
                    @include boxSizing(border-box); 
                    &:after {                    
                        position:absolute; content: "";
                        height:4px; width: 0; background: #fff; border:0;  
                        bottom: 0; left: 50%; top: 100%;
                        @include transition(all .2s ease-in-out);  
                        @include transform(translate(-50%, 0));    
                        @include borderRadius(30px); 
                    }  
                    &:hover {
                        background: #f79039;
                    }
                    &.active { 
                        background: #fff; color: #333;
                        /* &:after {
                            width:40px; 
                        } */
                    }               
                }
                &:after {
                    display: none; 
                }
            }
        }
        .selectMenumob {
            display: none;
            background: none; height: 100%; position: relative;
            select {
                background: none; border:0; position: relative; 
                color: #fff; width: 100%; height: 100%; padding: 0; font-size: 12px; cursor: pointer;
                option {
                    color: #000;
                }
            }
            &:after {
                position: absolute; width: 5px; height: 4px; content: "";
                background: url('../images/material/arr_small_fff.png') no-repeat 0 0; 
                top: 50%; right: 0;
                @include transform(translateY(-50%)); 
            } 
        }
    }
}



.subMenu_mobile {
    display: none;
    height: auto; 
    /*margin-bottom: 50px;   */

    .topsmenu {
        height: 49px; border-bottom: 1px solid #f58220; position: relative; z-index: 45;  
    }
    .current {
        position: relative; height: 49px; line-height: 49px; font-size: 14px; font-weight: bold; display: block;
        .txt {
            display: block; float: right; position: relative; padding: 0 14px 0 0; cursor: pointer; 
            &:after {
                position:absolute; width: 9px; height: 100%; content: ""; top: 0; right: 0; 
                background: url('../images/material/arr_med.png') no-repeat 0 50%;  
            }
        } 
    }

    .scnav {
        position: relative; width: 190px; background: #f58221; left: 0; top: 100%;  
        width: 100%; display: none;
        /*display: none;  */
        @include borderRadius(0 0 5px 5px);    
        ul {
            padding: 20px 14px 20px 14px; 
            li {
                display: block; border-right:none; border-bottom: 1px solid #b86119; height: auto; 
                margin-bottom: 0; padding-bottom: 0; 
                &:after {display:none;} 
                a {
                    display: block; padding: 10px 20px 6px 20px; font-size: 14px; line-height: 14px; color: #333; font-weight: bold;  
                    position: relative; 
                    &:hover, 
                        &.active {
                        color:#000;  
                    }                      
                }
                &:last-child { 
                    border-bottom: 0; 
                    a {padding-bottom: 0; margin-bottom: 10px; border-bottom: 0;}
                }    
                ul {
                    padding: 0px 14px 14px 14px; 
                    li {
                        border:none;
                        a {
                            font-size: 12px; padding-bottom: 0px; 
                        }
                    } 
                } 
            }
        }
    }    
}

.more {
    display: block;
    a {
        display: inline-block; font-size: 18px; line-height: 20px; color: #f58220; font-weight: bold; text-transform: uppercase; 
        position: relative; padding: 0 0 0 28px; 
        &:after {
            position:absolute; top: 0; left:0; content: ""; 
            width:15px; height: 13px; border:4px solid #2f9bd2; @include borderRadius(50%);    
        }
        &:hover {
            color: #389fcf;
        }
    }
}

.mid_content {
    min-height: 320px; padding-bottom: 95px; padding-top: 60px;       
    &.gray {
        position: relative; 
        &:after {
            position:absolute; width: 100%; height: 230px; background: #f3f3f3; content: "";     
            left: 0; bottom: 0; 
        }
    }     
    &.landing {padding-bottom: 230px;}      
    &.landing_2 {padding-bottom: 170px;}       
    &.detail {padding: 60px 0 160px 0;}          
    h2 {
        font-size: 48px; line-height: 48px; font-weight: normal; margin-bottom: 45px;        
        b {color: #f58220;} 
    }   
    h3 {font-size: 30px; font-weight: bold; margin-bottom: 30px;}  
    h4 {
        font-size: 20px; font-weight: bold; color: #389fcf; margin-bottom: 8px;
        &.v2 {color: #333; margin-bottom: 15px;}    
    }  
    .orange {color: #f58220; }     

    .boxImg {
        padding: 15px 0 50px 0;   
        .img {
            position: relative; line-height: 0; @include borderRadius(4px); 
            &:before { 
                position:absolute; bottom: 0; left: 0; width:173px; height: 159px; content: ""; 
                background:url('../images/material/ribbon_banner_med.png') repeat-x bottom; z-index: 3;                   
            }     
            &:after {
                position:absolute; bottom: 0; left: 0; width:100%; height: 322px; content: ""; 
                background:url('../images/material/shadow_large.png') repeat-x bottom; z-index: 2;  
                @include borderRadius(4px);
            }
            img {
                @include borderRadius(4px); position: relative; z-index: 1;            
            }
        }
    }

    .innerContent {
        padding: 0 100px;
        font-size: 18px; 
        p {line-height: 28px; margin-bottom: 36px;} 
        .aDes {
            h3,span{font-size: 16px;}

        }        
        ul {
            padding: 0 0 45px 0;  
            li {
                position: relative; margin: 0 0 8px 0; padding: 0 0 0 24px; line-height: 24px;  
                background: url('../images/material/bullet_1.png') no-repeat 0 10px;    
            }
        }
        .colMilestone {
            padding:25px 0 50px 0; margin-bottom: 50px; border-bottom: 1px solid #ccc;          
        }
        .colVimi {
            padding:0 0 0 0;  
            ul {padding-bottom: 0;}   
        }

        .paging {
            text-align: center; padding: 60px 0 0 0;  
            ul {
                li {
                    list-style: none; background: none; display: inline-block; 
                    padding: 0;
                    a {
                        display: block; font-size: 16px; padding: 0 10px;
                        color: #333;
                        &:hover,
                            &.active {
                            color:#f58220; 
                        }
                    }
                    &.prev {
                        margin-right: 20px; 
                        a {color: #666;}
                    }
                    &.next {
                        margin-left: 20px;
                        a {color: #666;} 
                    }
                }
            }
        }

    }



    .thumb_landing {
        padding: 70px 0 0 0; 
        .row {
            margin-bottom: 50px;  
            .col {
                float:left; @include boxSizing(border-box); vertical-align: top;    
                &.img {
                    @include borderRadius(10px); position: relative; width: 570px; height: 326px; 
                    max-width: 100%;
                    img {@include borderRadius(10px);} overflow:hidden;
                    &:after {
                        position: absolute; width: 127px; height: 114px; content: ""; bottom: 0; right: 0; 
                        background: url('../images/material/ribbon_landing.png') no-repeat 0 top;  
                    }
                }  
                &.txt {
                    padding:55px 0 0 60px; width: 600px; 
                    h3 {font-size: 24px; line-height: 24px; margin: 0 0 20px 0; font-weight: bold; text-transform: uppercase;} 
                    p {font-size: 18px; line-height: 28px; height: 118px; overflow: hidden;}    
                }  
            }
            &:last-child {margin-bottom: 100px;} 
            &:nth-child(even) {                
                .col.txt {
                    float: left; padding-left: 0; padding-right: 66px;   
                }
                .col.img { 
                    float: right;
                    &:after {
                        left: 0px; bottom: 0px;   
                        @include transform(scaleX(-1));   
                        filter: FlipH;
                        -ms-filter: "FlipH";          
                    }
                }
            }
        }
        &.v2 {
            padding: 15px 0 0 0;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            .row {
                width: 50%;
                margin: 0 0 30px 0;
                padding: 0 15px;
                box-sizing: border-box;
                .col.txt {
                    width: 100%; padding: 40px 0 0 30px;   
                }
            } 
        }

        &.v3 {
            .col {
                &.img {width: 770px;}
                &.txt {width: 400px; padding:92px 0 0 72px;} 
            }

        }

    }

}


.block_milestones {
    border-bottom:1px solid #e0e0e0; margin-bottom: 50px; padding:40px 0 50px 0;  
    min-height: 385px;         
    .inner {
        padding:0 0px;
    }
    .mlSlider {
        position: relative;            
        .wrapPager {
            position: absolute; top:0; left:0; z-index:15;
            .bx-year {
                padding:0 0 0 0; position: relative;
                a {
                    position: relative; display: block; height: 18px; width:80px; padding: 0 0 10px 0; 
                    margin:0 0 0px 0; 
                    span {
                        display: block; color: #f58220; font-size: 20px; text-align: right;
                        @include transition(all .22s ease-in-out); 
                        opacity: 0; filter:alpha(opacity="0");
                    }
                    &:after {
                        position: absolute; width: 40px; height: 2px; background: #ccc; content: "";
                        right: 0; bottom: 0; @include transition(all .22s ease-in-out);
                    }
                    &:hover, &.active {
                        padding-top:0;                            
                        &:after {
                            width:80px; background: #f58220;
                        }       
                        span {opacity: 1; filter:alpha(opacity="1");}
                    }
                    &.active {
                        span {opacity: 1; filter:alpha(opacity="1");} 
                    }

                }

                ul#bxslider-pager { 
                    padding: 0; 
                    li:first-chld {
                        a {
                            width:80px; background: #f58220; 
                        }
                    }
                    li.active {
                        a {
                            width:80px;
                            span {opacity: 1; filter:alpha(opacity="1");}
                            &:after {
                                width:80px; background: #f58220;
                            } 
                        }
                    }
                }

                .pager-prev, 
                .pager-next {
                    position: absolute; width:auto; height: 26px; content: ""; right:15px; cursor: pointer;
                    display: block; text-indent: -999999px; z-index: 15;
                }
                .pager-prev {
                    background: url('../images/material/ms-arrow-prev.png') no-repeat right 0; top:0; 
                    &:hover {
                        background-position:right -26px;
                    }
                }
                .pager-next {
                    background: url('../images/material/ms-arrow-next.png') no-repeat right 0; bottom: -50px;    
                    &:hover {
                        background-position:right -26px;
                    }
                }                    

                .bx-wrapper {padding-top: 50px;}
                .bx-wrapper ul li {background: none; padding: 0; margin: 0; height: 32px; line-height: 28px;}    
                .bx-wrapper .bx-viewport {height: 225px !important;}
                .bx-wrapper .bx-controls-direction a {
                    position: absolute; width:100%; height: 29px; padding: 0; margin: 0;
                }
                .bx-wrapper .bx-controls-direction a.bx-prev {
                    background: url('../images/material/ms-arrow-prev.png') no-repeat 78% 0; top:0;
                }
                .bx-wrapper .bx-controls-direction a.bx-next {
                    background: url('../images/material/ms-arrow-next.png') no-repeat 78% 0; bottom: 0;
                    top:115%;
                }
                .bx-wrapper .bx-controls-direction a span {display: none;}
                .bx-wrapper .bx-controls-direction a:after {display: none;}
            }
        }
        .bx-wrapper {
            margin:0 0 0 0; 
            .bx-viewport {
                border: solid #fff 0px;  left: 0px;  background: #fff;  
                @include boxShadow(0);      box-shadow: 0 0 0px #ccc;
            }
            .bx-controls-direction a {
                top:0;
            }
            .bx-controls-direction .bx-prev {left:0;}
            .bx-controls-direction .bx-next {left:0; top:50%;}
        }

        .bxMile {
            margin:0 0 0 140px;  
            .slide {/*width: 100% !important;*/ z-index: 25 !important;}
            ul {
                padding: 0;
                li {padding: 0; background: none; margin: 0;}
            }
        }
        .msbox {
            padding:50px 0 0 0; position: relative; overflow:hidden; margin-right: 0;  
            .gbr {
                float: left; width: 425px; height: 220px; left: 0px; position: relative; margin: 20px 0 0 0;
                span {
                    display: block; font-size: 200px; color: #389fcf; font-weight: bold; line-height: 200px;
                }
                img {width: 100%;} 
            }
            .des {
                float: right; width: 348px; right: 0px; padding: 70px 0 0 0; position: relative;
                h3 {font-size: 24px; line-height: 30px; color: #333; margin: 0 0 15px 0;}  
                p {font-size: 18px; line-height: 28px; color: #333;}

            }
        }
    }
}


.ourRewards {
    margin: 58px 0 0 0;  
    .rtop {border-top: 1px solid #ccc; margin: 0 100px; padding: 75px 0 0 0;}        
    h3 {text-align: center; margin-bottom: 68px;}     
    .wrapSlide {
        position: relative; padding: 0 0 88px 0; margin: 0 0 20px 0;  
        .wrapNav {
            position: relative; width: 100%; bottom: 0px; right: 0;
            .fr {width: 178px; margin: 0 auto; float: none;} 
            .nav_direction {
                width: 178px; position: relative;
                button {
                    width: 26px; height: 18px; display: block; position: absolute; top: 10px;  
                    background: url('../images/material/sarr_next.png') no-repeat 0 0; 
                    border:0; padding: 0; text-indent: -999999px; cursor: pointer;
                    &.arr_prev {background: url('../images/material/sarr_prev.png') no-repeat 0 0; left: 0;}
                    &.arr_next {background: url('../images/material/sarr_next.png') no-repeat 0 0; right: 0;} 
                }
            }
            .info_pager {
                width: 178px; 
                span {float: left; margin-right: 5px;} 
                .pos {margin-left: 44px; font-size: 36px; line-height: 36px;}
                .line {font-size: 36px; line-height: 36px; color: #ccc;}
                .total {font-size: 20px; line-height: 20px; color: #ccc; margin-top: 13px;}     
            }
        }
    }
    .colRewards {
        padding-bottom: 20px;
        .col {
            display: inline-block; width: 268px; height: 348px; border:1px solid #ccc; margin: 0 27px 32px 0;    
            vertical-align: top;
            &:nth-child(4n+4) {margin-right: 0;} 
            .img {border-bottom:0px solid #ccc; line-height: 0;}
            .text {
                padding: 20px 20px;
                h5 {font-size: 18px; font-weight: bold; margin-bottom: 14px; height: 37px; overflow: hidden;}   
                .year {color: #2f9bd2; font-size: 14px; margin: 0; font-weight: bold;}     
            } 
        }
    }

} 


.thumblistItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    .col {
        display: inline-block; 
        height: 358px; 
        border:1px solid #ccc; 
        margin: 0 2.5% 27px 0;
        vertical-align: top; 
        flex: 1 0 23%;
        max-width: 23%;
        box-sizing: border-box;
        &:nth-child(4n+4) {margin-right: 0;}  
        .img {
            border-bottom:0px solid #ccc; line-height: 0; text-align: center; height: 220px; position: relative;
            overflow: hidden;   
            img {position: relative; top: 50%; @include transform(translateY(-50%));} 
        }
        .text {
            padding: 10px 20px; text-align: center;
            h5 {font-size: 14px; font-weight: bold; margin-bottom: 0px; line-height: 20px;}                  
            .tdes {color: #666; font-size: 14px; margin: 0; line-height: 20px;}      
        } 
    }
}

.solutions_by {padding: 20px 0;}    

.colSolutions,
.colOffer {
    h3,h1 {font-size: 30px; font-weight: bold; margin-bottom: 50px; text-align: center;}     
}
.viewsol {
    background: #f3f3f3; 
    padding: 90px 0 100px 0;  
    /*padding: 90px 0 100px 0;  */
    /*margin-top: 120px;          */
    .col {
        margin:0 0 120px 0;  
        &:last-child {margin-bottom: 0;} 
        .col_os {            
            line-height: 0; position: relative; overflow:hidden;   
            @include borderRadius(10px);
            img {@include borderRadius(10px); position: relative; z-index: 1;} 
            &:before { 
                display: none; 
                position: absolute; width: 100%; height: 100%; content: ""; bottom: 0; left: 0;  
                background: rgba(0,0,0, 0.55); z-index: 2;    
            } 
            &:after {
                position: absolute; width: 114px; height: 127px; content: ""; bottom: 0; left: 0;  
                background: url('../images/material/ribbon_landing_2.png') no-repeat 0 top; z-index: 2;    
            } 
            .txt {
                position: absolute; top: 50%; right: 112px; z-index: 5; color: #fff; line-height: 28px;  
                width: 390px; @include transform(translateY(-50%));   
                .title {font-size: 24px; font-weight: bold; text-transform: uppercase; margin: 0 0 10px 0;}     
                .tdescrip {font-size: 18px; margin: 0 0 20px 0;}   
            }
        }  
    } 
    &.hasSLideView {
        .container {padding-bottom: 85px;} 
        .col {margin-bottom: 0;}  
        &.v2 {margin: 0; background: none; padding: 35px 0;}    
    }    
} 

.seeTestimoni {
    position: relative; overflow: hidden; padding: 0 0 85px 0;  
    .container {padding: 85px 0;}
    &.v2 {
        padding-bottom: 50px;
        .container {padding-top: 60px;}    
    }
    .row {
        height: 505px; position: relative; overflow: hidden;
        .col {
            display: inline-block; float: none;
            width: 368px; height: 488px; border:1px solid #ccc; vertical-align: top; 
            margin: 0 27px 27px 0; padding: 48px;  
            @include boxSizing(border-box); @include transition(all .2s ease-in-out); 
            cursor: pointer;
            /*&:nth-child(3n+3) {margin-right: 0;}   */
            /*&:last-child {margin-right: 0;}*/ 
            .img {
                margin: 0 0 30px 0; height: 70px; position: relative; text-align: center;
                img {@include transform(translate(-50%,-50%)); position: relative; top: 50%; left: 50%;}   
            } 
            .saytxt {
                text-align: center; position: relative; height: 110px; overflow: hidden; margin: 0 0 38px 0;
                font-size: 16px; color: #666; font-style: italic; line-height: 18px;
            }
            .user {
                text-align: center;
                .pic {
                    margin: 0 auto 20px auto; width: 87px; height: 82px;  
                    @include borderRadius(50%); 
                    img {
                        width: 100%; 
                        @include borderRadius(50%);
                    } 
                }
                .title {font-size: 14px; color: #111; font-weight: bold; margin: 0 0 4px 0;} 
                .udes {font-size: 14px; color: #666; line-height: 18px;} 
            }
            &:hover {
                border-color:#f58220;
                @include boxShadow(0px 0px 4px 1px #f58220); 
            }
            &.slick-current {
                border-color:#f58220;
                @include boxShadow(0px 0px 4px 1px #f58220);  
            }
        }
    }


    .slick-slider .slick-track, .slick-slider .slick-list {text-align: center; margin-left: 0;}   

    .wrapNav {
        position: absolute; width: 100%; bottom: 0; right: 0;  
        .fr {width: 190px; float: none; margin: 0 auto;}   
        .nav_direction {
            width: 178px; position: relative;
            button {
                width: 26px; height: 18px; display: block; position: absolute; top: 10px; 
                background: url('../images/material/sarr_next.png') no-repeat 0 0; 
                border:0; padding: 0; text-indent: -999999px; cursor: pointer;
                &.arr_prev {background: url('../images/material/sarr_prev.png') no-repeat 0 0; left: 0;}
                &.arr_next {background: url('../images/material/sarr_next.png') no-repeat 0 0; right: 0;} 
            }
        }
        .info_pager {
            width: 178px; 
            span {float: left; margin-right: 5px;} 
            .pos {margin-left: 44px; font-size: 36px; line-height: 36px;}
            .line {font-size: 36px; line-height: 36px; color: #ccc;}
            .total {font-size: 20px; line-height: 20px; color: #ccc; margin-top: 13px;}     
        }
    }

}

.colPromo {
    background: #f3f3f3; padding: 88px 0 20px 0;     
    .row {
        margin: 0 0; 
        .col {
            display: inline-block; margin: 0 -2px 0 0; height: 330px; vertical-align: top;       
            &.img {
                width: 770px; background: #177fb4; position: relative; overflow: hidden;   
                @include borderRadius(7px 0 0 7px);
                img {
                    @include borderRadius(7px 0 0 7px); position: relative;
                }
            }
            &.text {
                width: 400px; background: #fff; padding: 92px 70px 0 35px;   
                @include borderRadius(0 7px 7px 0); @include boxSizing(border-box);   
                .inn {
                    padding: 25px 0 0 27px; position: relative;
                    &:before {
                        position:absolute; width: 39px; height: 36px; content: ""; top: 0; left: 0;  
                        background: url('../images/material/snews_ico.png') no-repeat 0 0; 
                    }  
                    .date {font-size: 12px; color: #999; margin-bottom: 5px;}
                    .title {font-size: 24px; font-weight: bold; margin-bottom: 15px; line-height: 30px;}        
                } 
            } 
        }
    }  
    &.v2 {
        background: none; padding: 70px 0 70px 0;
        .row {
            margin-bottom: 60px; background: none;              
            .col {
                &.text {
                    padding-right: 0; padding-left: 70px;   
                }
                &.img {
                    background: none; 
                    @include borderRadius(7px);  
                    img {
                        @include borderRadius(7px);  
                    }
                }
            }
            &:last-child {margin-bottom:0;}  
            &:nth-child(even) {                
                .col.text {
                    float: left; padding-left: 0px; padding-right: 70px;     
                }
                .col.img { 
                    float: right;                  
                }
            }
        }
    }
}

.colUser {
    padding: 0 0 34px 0; border-bottom: 1px solid #ccc; margin: 0 0 45px 0;  
    .user {
        width: 425px; 
        .pic {
            display: inline-block; vertical-align: top; width: 70px; height: 70px; margin-right: 20px; 
            img {@include borderRadius(50%);}    
        }
        .txt {
            display: inline-block; vertical-align: top; width: 320px;
            .name {font-size: 18px; color: #333; font-weight: bold; margin: 0 0 8px 0;}  
            .sdes {font-size: 13px; color: #666; line-height: 18px;}   
        }
    }
}

.colAccordion {
    .acc_list {
        border-bottom: 1px solid #dedede; 
        .acc_title {
            padding: 20px 0 20px 0; cursor: pointer;
            span {
                position: relative; font-size: 26px; font-weight: bold; text-transform: uppercase; display: block;
                color: #111; padding: 20px 62px 20px 0;  
                &:before {
                    position:absolute; width: 39px; height: 36px; content: ""; left: -25px; top: 0;  
                    background: url('../images/material/snews_ico.png') no-repeat 0 0; 
                }
                &:after {
                    position:absolute; width: 20px; height: 20px; content: ""; right: 30px; top: 50%; 
                    @include transform(translateY(-50%));
                    background: url('../images/material/acc_ico_plus.png') no-repeat 0 0;  
                }
            }
            &.open {
                span {
                    &:after {
                        background: url('../images/material/acc_ico_minus.png') no-repeat 0 0;    
                    }
                }
            }
        }
        .acc_descrip { 
            /*display: none;*/
            .inn {padding-bottom: 35px;}  
        }        
    }
}

.colTrading {
    background: #f3f3f3; padding: 75px 0 100px 0;   
    h3 {text-align: center; margin-bottom: 60px;}   
    h4.dtitle {
        font-size: 24px; color: #333; margin:0 0 20px 0; padding: 25px 0 0 0; position: relative;   
        &:before {
            position:absolute; width: 39px; height: 36px; content: ""; left: -25px; top: 0; 
            background: url('../images/material/snews_ico.png') no-repeat 0 0; 
        }
    } 
    .innerContent ul {padding-bottom: 20px;} 

    .colDownload {
        .col {
            display: inline-block; vertical-align: top;
            @include boxSizing(border-box); 
            &.img {
                width: 190px;
                img {
                    @include borderRadius(7px);   
                }
            } 
            &.txt {
                width: 775px; padding:55px 0 0 50px;
                h4 {color: #333; margin-bottom: 12px;}
                .stext {
                    font-size: 16px; color: #666; line-height: 20px; margin-bottom: 25px;   
                }
            }   
        }
    }

}


.colFilter {
    .csearch {
        width: 368px; height: 48px; border:1px solid #999; position: relative; 
        @include borderRadius(3px);  
        input[type="text"] {
            line-height: 48px; color: #999; font-size: 18px;  padding: 0; border:0; background: none;     
            width: 100%; padding: 0 55px 0 28px;  
            @include boxSizing(border-box); 
        }
        input[type="submit"] {
            position: absolute; top: 0; right: 0; height: 100%; width: 45px; border:0; padding: 0; 
            background: url('../images/material/ico_search.png') no-repeat left 50%;  
            cursor:pointer; 
        }
    }
    .csort {
        label {
            display: inline-block; vertical-align: top; font-size: 18px; font-weight: bold; margin: 0 15px 0 0;
            line-height: 48px; 
        }  
        .styledCombobox {
            display: inline-block; vertical-align: top; 
            /*width: 158px; */
            min-width: 158px;  
            width: auto; 
            position: relative; z-index: 55; 
            font-size: 18px;
            @include borderRadius(3px);  
            .current {
                display: block; height: 48px; line-height: 48px; padding: 0 46px 0 22px; color: #f58220;    
                cursor: pointer; position: relative;
                border:1px solid #999;
                /*border-bottom: 1px solid #d8d8d8; */ 
                @include borderRadius(3px); 
                @include boxSizing(border-box); 
                &:before {
                    position:absolute; width: 15px; height: 9px; content: ""; top: 50%; right: 14px;
                    background: url('../images/material/arr_down.png') no-repeat 0 0; 
                    @include transform(translateY(-50%)); 
                }
            }
            .sub {
                display: none;  
                position: absolute; width: 100%; border:1px solid #999; border-top: 0; background: #fff;
                top: 100%; z-index: 155;
                @include borderRadius(0 0 3px 3px); 
                @include boxSizing(border-box);  
                div {
                    height: auto; line-height: 20px; border-bottom: 1px solid #999; margin: 0 20px;     
                    &:last-child {border:0;} 
                    a {
                        color: #333; display: block; padding: 12px 0;    
                        &:hover {
                            color:#f58220; 
                        } 
                    }
                }
            }
        } 
    }

    &.v2 {
        padding: 40px 0 45px 0;  
        .csort {float: left; margin-left: 30px;}      
        .csearch {width: 300px;} 
    }

}


.ourCustomer {
    padding: 0 0 30px 0; margin: 0 0 50px 0;   
    .rtop {border-top: 1px solid #ccc; margin: 0 100px; padding: 55px 0 55px 0;}  
    h3 {text-align: center; margin-bottom: 0;}   

    .row {
        height: auto; position: relative; overflow: hidden; padding-bottom: 50px;  
        .col {
            display: inline-block; float: none;
            width: 368px; height: auto; border:1px solid #ccc; vertical-align: top; 
            margin: 0 27px 27px 0; padding: 48px;  
            @include boxSizing(border-box); @include transition(all .2s ease-in-out); 
            cursor: pointer;
            &:nth-child(3n+3) {margin-right: 0;}   
            &:last-child {margin-right: 0;} 
            .img {
                margin: 0 0 30px 0; height: 70px; position: relative; text-align: center;
                img {@include transform(translateY(-50%)); position: relative; top: 50%; left: 0;}    
            } 
            .saytxt {
                text-align: center; position: relative; height: 110px; overflow: hidden; margin: 0 0 38px 0;
                font-size: 16px; color: #666; font-style: italic; line-height: 18px;
            }
            .user { 
                text-align: center;
                .pic {
                    margin: 0 auto 20px auto; width: 87px; height: 82px;         
                    @include borderRadius(50%);  
                    img {
                        width: 100%; height: auto;
                        @include borderRadius(50%);
                    } 
                }
                .title {font-size: 14px; color: #111; font-weight: bold; margin: 0 0 4px 0;} 
                .udes {font-size: 14px; color: #666; line-height: 18px;} 
            }
            &:hover {
                border-color:#f58220;
                @include boxShadow(0px 0px 4px 1px #f58220); 
            }
            &.slick-current {
                border-color:#f58220;
                @include boxShadow(0px 0px 4px 1px #f58220);  
            }
        }
    }
}


.vspace {height: 50px;}  


.thumb_news { 
    padding: 0 0 50px 0;  
    .ourNews {padding: 0 0 100px 0;} 
    .row {
        margin-bottom: 50px; max-height: 250px; cursor: pointer;   
        .col {
            float:left; @include boxSizing(border-box); vertical-align: top;    
            &.img {
                @include borderRadius(10px); position: relative; width: 470px; height: 250px; overflow:hidden;  
                img {@include borderRadius(10px); @include transition(all .25s ease-in-out); }                 
            }  
            &.txt {
                padding:40px 0 0 30px; width: 500px;  
                .inn {
                    position: relative; padding: 25px 0 0 30px; 
                    &:before {
                        position:absolute; width: 39px; height: 36px; content: ""; top: 0; left: 0;    
                        background: url('../images/material/snews_ico.png') no-repeat 0 0;  
                    }  
                }
                .date {
                    font-size: 12px; color: #999; margin:0 0 3px 0;    
                }
                .title {font-size: 20px; line-height: 30px; margin: 0 0 8px 0; font-weight: normal; text-transform: capitalize;} 
                p {font-size: 16px; line-height: 20px; height: 80px; overflow: hidden; color: #666;}       
            }  
        }
        &:last-child {margin-bottom: 0;}
        &:nth-child(even) {                
            .col.txt {
                float: left; padding-left: 0; padding-right: 53px;     
            }
            .col.img { 
                float: right;
                &:after {
                    left: 0px; bottom: 0px;   
                    @include transform(scaleX(-1));   
                    filter: FlipH;
                    -ms-filter: "FlipH";          
                }
            }
        }
        &:hover {
            .img {
                img {
                    @include transform(scale(1.1)); 
                }
            }
        }
    }   
    &.v2 {
        padding: 50px 0 0 0; 
        h3 {text-align: center; margin-bottom: 60px; border-top: 1px solid #ccc; padding-top: 60px;}       
        .row {
            width: 470px; height: auto; max-height: none; display: inline-block; vertical-align: top; margin: 0 26px 26px 0;
            &:nth-child(2n+2) {margin-right: 0;}  
            .col.img {width: 100%;} 
            .col.txt {
                width: 100%; padding: 20px 0 0 0;    
            }
        } 
    }
}


.sapBenefit { 
    /*height: 702px; */
    height: auto; 
    background: url('../images/material/sapBenefit.jpg') no-repeat 0 0;   
    background-size: cover;   
    .container {padding: 185px 0 160px 0;}  
    iframe{ width: 100% !important; height: 658px !important; }
    h3,h1 {
        font-weight: 300;
        b {color: #f58220;}  
    }  
    .col {
        display: inline-block; width: 49.9%; vertical-align: top; padding: 0 20px 0 0;        
        @include boxSizing(border-box); 
        &:nth-child(2n+2) {padding-right: 0; padding-left: 20px;}  
    }
    ul {
        li {
            font-size: 20px; line-height: 30px; margin-bottom: 20px;  
            background:url('../images/material/bullet_large.png') no-repeat 0 4px;  
            padding: 0 0 5px 50px; 
            h3{margin: 0;text-align: left}
        }
    }
}



.special_offer {
    height: 886px; position: relative;  background: #fff; 
    .offerSlide {height: 100%;}   
    .bg {
        position: absolute; z-index: 1; top: 0; left: 0; width: 100%; height: 100%;   
    }
    .inn {height: 100%; width: 100%; position: relative; top: 0; left: 0; z-index: 6;}      
    .container {height: 100%; width: 1170px;}
    .slide {
        position: relative; height: 100%;
        .imgsl {
            float: left; position: relative; width: 490px; top: 40%; transform: translateY(-40%);    
            height: 580px;          
            img {
                position: absolute; top: 0; max-width: none;
                &.img_1 {width: auto; height: 580px; left: -165px;} 
                &.img_2 {width: 490px;}
                &.img_3 {width: 737px; left: -207px;}
            }
        }
        .caption {
            position: relative; right: 0; z-index: 3; color: #333; top: 45%; transform: translateY(-45%);   
            width: 612px; float: right;      
            height: 400px;
            overflow: hidden;
            padding-bottom: 12px;
            h3 {
                font-size: 36px; font-weight: 300; line-height: 40px; text-transform: capitalize; margin-bottom: 28px;  
                .orange {color: #f58220;} 
            }    
            .tdes {font-size: 18px; line-height: 28px; margin-bottom: 28px;}       
            ul {
                margin-left: 25px; list-style-type: disc; padding: 15px 0; 
                li {
                    list-style-type: disc; line-height: 28px; font-size: 18px; padding: 0 0 4px 10px; 
                }
            }  
            ol {
                margin-left: 25px; list-style-type: decimal; padding: 15px 0; 
                li {
                    list-style-type: decimal; line-height: 28px; font-size: 18px; padding: 0 0 4px 10px; 
                }
            }    
        }
    } 
    .slick-list {height: 100%;}
    .slick-track {height: 100%;}  
    .slick-dots {bottom: 0; z-index: 333;} 
    .wrapNav {
        position: absolute; width: 100%; bottom: 155px; right: 0;  
        .fr {width: 612px; margin: 0; float: right;} 
        .nav_direction {
            width: 178px; position: relative;
            button {
                width: 26px; height: 18px; display: block; position: absolute; top: 10px; 
                background: url('../images/material/sarr_next.png') no-repeat 0 0; 
                border:0; padding: 0; text-indent: -999999px; cursor: pointer;
                &.arr_prev {background: url('../images/material/sarr_prev.png') no-repeat 0 0; left: 0;}
                &.arr_next {background: url('../images/material/sarr_next.png') no-repeat 0 0; right: 0;} 
            }
        }
        .info_pager {
            width: 178px; 
            span {float: left; margin-right: 5px;} 
            .pos {margin-left: 44px; font-size: 36px; line-height: 36px;}
            .line {font-size: 36px; line-height: 36px; color: #ccc;}
            .total {font-size: 20px; line-height: 20px; color: #ccc; margin-top: 13px;}     
        }
    }

    .list_hrp {
        font-size: 18px;  
        .col {
            display: inline-block; width: 49.7%; vertical-align: top;   
        }
        p {
            margin: 0 0 9px 0; line-height: 28px; 
            i {color: #ababab; display: table-cell; width: 27px; font-style: normal;}    
            span {display: table-cell;} 
        }
    }


    &.mod_special_offer_2 {
        height: 850px; background: #fff;    
        .slide {
            .caption {width: 570px; float: left;}               
            .imgsl {
                float: right; width: 550px; height: 727px; top:15%; transform: translateY(-15%);                     
                img {
                    height: 678px; 
                    &.img_1 {left: 0;}        
                }
            }  
        }
        .wrapNav {
            bottom: 155px;  
            .fr {float: left;} 
        }
    }

}


.offer_form {
    min-height: 850px; background-size: cover !important; overflow: hidden;
    .container {
        padding: 110px 0;  
    }
    h3 {
        font-size: 36px; font-weight: 300; margin-bottom: 35px;  
    }
    .txt {font-size: 18px; text-align: center; margin-bottom: 50px;}   
    .inn {
        padding: 0 170px;
    }
}


.form_std {
    .field {
        margin-bottom: 30px; 
        &.center { 
            text-align: center; 
            input[type="submit"],
            .btnsubmit {margin: 0 auto; display: inline-block;}       
        } 
    }
    .col {
        display: inline-block; vertical-align: top; width: 49.8%; @include boxSizing(border-box);  
        padding: 0 15px 0 0; 
        &:last-child {padding-left:15px; padding-right: 0;}  
    } 
    label {
        display: block; font-size: 14px; color: #666; text-transform: uppercase; margin: 0 0 8px 0;  
        sup {color: #f58220;}
    }   
    input[type="text"],
    input[type="number"],
    input[type="email"],
    textarea {
        height: 58px; line-height: 58px; border:1px solid #999; padding: 0 20px; width: 100%;    
        font-size: 18px;
        @include borderRadius(3px); @include boxSizing(border-box); @include transition(all .15s ease-in-out); 

        &:focus {
            border-color:#f58220; 
        }
    }
    input[type="number"] {padding-right: 0;} 
    textarea {height: 80px; line-height: 20px; padding: 10px 20px;}         
    .btnsubmit, 
    input[type="button"],
    input[type="submit"] { 
        height: 60px; line-height: 60px; background: #f58220; color: #fff; font-size: 16px; text-transform: uppercase;  
        padding: 0 50px; border:none; cursor: pointer;  
        @include borderRadius(30px); @include transition(all .15s ease-in-out); 
        &:hover {
            background:#f9790d; 
        } 
    }

    .opt {
        position: relative;
        .check-custom {
            position: absolute; width: 100%; height: 29px; cursor: pointer; z-index: 3;
            input {cursor: pointer; padding: 0;}
            &:before {
                position:absolute; width:25px; height: 25px; content: ""; left: 0; top: 0;
                background: url('../images/material/checkbox.png') no-repeat 0 0;  
            }
            &.active {
                &:before {
                    background-position:0 -25px;  
                }
            }
        }
        span {
            position: absolute; top: 0; left: 0; z-index: 1; padding: 4px 0 0 40px; width: 100%; 
            color:#666; font-size: 16px;  
            @include boxSizing(border-box);  
        }
    }

}  

.btn_download {
    a {
        display: inline-block; font-size: 18px; color: #f58220; font-size:18px; text-transform: uppercase; 
        position: relative; padding: 0 0 0 26px; line-height: 23px; 
        &:before {
            position:absolute; width: 21px; height: 23px; content: ""; top: 0; left: 0; 
            background: url('../images/material/ico_download.png') no-repeat 0 0; 
        }   
        &:hover {
            color: #2f9bd2;
        }
    }
}
.colDownload {
    padding: 30px 0 0 0; 
    .row {margin: 0 0 40px 0;} 
    .col {
        display: inline-block; vertical-align: top; @include boxSizing(border-box);    
        &.img {width: 470px;} 
        &.text {
            width: 670px; padding: 50px 0 0 30px;  
            h3 {margin: 0 0 8px 0; font-size: 24px;}     
            .tdes {
                height: 146px; position: relative; overflow: hidden; font-size: 18px; margin: 0 0 22px 0;   
                p {line-height: 28px;} 
            }
        }          
    }
}


.tableStyled {
    margin-bottom: 55px;  
    table {width: 100%;}  
    thead {
        th {
            padding: 22px 10px; background: #1b1917; font-size: 18px; color: #fff; font-weight: bold;  
            &:nth-child(1) {width: 5%;}
            &:nth-child(2) {width: 280px;} 
            &:nth-child(3) {width: 295px;}
            &:nth-child(4) {width: 210px;}
            &:nth-child(5) {width: 140px;}  
        }
    }
    tbody {
        td {
            padding: 22px 10px; font-size:18px; line-height: 26px; border-bottom: 1px solid #ccc;    
        }
    }

}


.colSearch {
    .fullSearch {
        position: relative; border:1px solid #ccc; height: 73px; margin-bottom: 55px;  
        input[type="text"] {
            width: 100%; height: 73px; line-height: 73px; padding: 0 78px 0 35px; border:0;
            font-size: 24px; color: #999;
            @include boxSizing(border-box);  
        }
        input[type="submit"] {
            position: absolute; width: 32px; height: 29px; content: ""; top: 23px; right: 30px; z-index: 3;  
            border:0; padding: 0; cursor: pointer; 
            background: url('../images/material/ico_search_large.png') no-repeat 0 0;    
        }
    }
    .searchList {
        h3 {padding: 0 40px; margin-bottom: 50px;}   
        .col {
            padding: 0 40px 40px 40px; margin: 0 0 40px 0;  border-bottom: 1px dotted #dfdfdf;   
            cursor: pointer;   
            h4 {color: #f58220; font-weight: bold; margin: 0 0 15px 0; line-height: 26px;}            
            .txt {color: #888; line-height: 30px; margin: 0;}  
            &:last-child {margin-bottom: 0;}   
        }
    }
}



body.lock {overflow-y: hidden;}  
.wrap_popup {
    background:rgba(0,0,0, 0.3);      
    position: fixed;
    width: 100%;
    height: 100%;    
    z-index: 999999;
    top: 0;
    left: 0;
    overflow-y: auto;
    .pop_inner {
        display: table;
        height: 100%;
        width: 100%;
        position: absolute;
        .middle_pop {
            display: table-cell;
            vertical-align: middle;            
        }
    }   
    .overlay_bg {
        position: absolute; width: 100%; height: 100%;   
        z-index: 78787; left: 0; top: 0; margin: 0; 
    }
}
#boxPop { 
    min-width: 970px;    
    max-width: 970px; 
    min-height: 540px;
    margin: 100px auto 50px auto;   
    position: relative;
    z-index: 878787;     
    background: #fff; 

    .close_pop {
        position: absolute;
        width: 36px;
        height: 36px;
        /*background: url(../images/material/pop_close.png) no-repeat 0 0;  */ 
        right: -46px;
        top: -75px;  
        cursor: pointer;       
    }
    .content_pop {        
        background: none; min-height: 540px; position: relative; text-align: left;      
        .inner {
            padding: 60px 100px 40px 100px;   
        }
        h3 {font-size: 48px; line-height: 55px; margin: 0 0 15px 0;}      
        .txtdesc {
            font-size: 18px;
            p {line-height: 28px;}
            .orange {color: #f58220; font-weight: bold;} 
            a {
                color: #f58220; font-weight: bold; text-decoration: underline; 
                &:hover {text-decoration:none;}   
            } 
        }
        &.thankyou {
            position: absolute; width: 100%; height: 100%; top: 0; left: 0; 
            background: url('../images/material/thankyou_pop.jpg') no-repeat 0 0;
            background-size: cover;  
            .text {
                text-align: center; position: relative; top: 50%; padding: 0 90px;  
                @include transform(translateY(-50%)); 
            } 
        }
    } 

}


.g-recaptcha > div {margin:auto; width: 100% !important; height: auto !important; } 
.g-recaptcha > div > div {
    width: 100% !important;
    height: 78px;
    transform:scale(1); //the code to rescale the captcha obtained in this page
    -webkit-transform:scale(1);
    text-align: left;
    position: relative;
    margin-left: 0px;
    left: 0px;   
}



.scrollAnimate {position: relative; opacity: 0; filter: alpha(opacity=0); top: 100px;}  
.scrollAnimate_left {position: relative; opacity: 0; filter: alpha(opacity=0); left: -100px;}   
.scrollAnimate_right {position: relative; opacity: 0; filter: alpha(opacity=0); right: -100px;}   
.scrollAnimate_absolute_bottom {position: absolute; opacity: 0; filter: alpha(opacity=0); bottom: -100px;}  
.scrollAnimate_absolute_top {position: absolute; opacity: 0; filter: alpha(opacity=0); top: -100px;}  
.scrollAnimate_absolute_left {position: absolute; opacity: 0; filter: alpha(opacity=0); left: -100px;}  
.scrollAnimate_absolute_right {position: absolute; opacity: 0; filter: alpha(opacity=0); right: -100px;}  
.scrollAnimate_fade {position: relative; opacity: 0; filter: alpha(opacity=0);} 

.telp {
    color: #333;
    &:hover {color:#f58220;} 
}

/* footer
----------------------------------------------------------------------------------------------*/
footer {    
    background: #131211; padding-bottom: 0; position: relative; z-index: 10;
    .foot_top {         
        background:#1b1917; min-height: 360px; color: #ccc; padding-bottom: 25px;
        .container {padding: 70px 0 0 0;}
        h4 {color: #f58220; font-size: 16px; font-weight: bold; text-transform: uppercase; margin-bottom: 12px;} 
        .social_foot {
            padding: 10px 0 0 0;
            ul {
                li {
                    display: inline-block; margin: 0 15px 0 0;  
                    a {display: block; width: 25px; height: 25px; text-indent: -999999px;}    
                    &.sc_fb {
                        a {background:url('../images/material/sc_fb.png') no-repeat 0 50%;}
                    }
                    &.sc_link {
                        a {background:url('../images/material/sc_link.png') no-repeat 0 50%;}
                    }
                    &.sc_ig {
                        a {background:url('../images/material/sc_ig.png') no-repeat 0 50%;}
                    }
                    &.sc_tw {
                        a {background:url('../images/material/sc_tw.png') no-repeat 0 50%;}
                    }
                    &.sc_yt {
                        a {background:url('../images/material/sc_yt.png') no-repeat 0 50%;}
                    }
                }
            }
        }
        .col {
            display: inline-block; vertical-align: top; margin: 0 98px 0 0; width: 202px; 
            &:nth-child(5n+5) {margin-right:0;}  
            &:last-child {margin-right:0;} 
            p {
                font-size: 14px; line-height: 20px;
                &.txt {
                    a {
                        color: #f58220;    
                        &.telp {
                            color: #ccc;
                            &:hover {color:#f58220;} 
                        }                    
                    } 
                }
            }  
            &.col_1 {width: 310px;}
            &.col_4 {width: 135px;}
            &.col_5 {width: 110px;} 
            .nav_foot {
                padding: 0 0 25px 0;  
                li {
                    display: block;
                    a {
                        display: block; font-size: 14px; color: #ccc; padding: 4px 0 3px 0;
                        &:hover {color:#f58220;}  
                    } 
                }
            }
        }    
    } 
    .foot_bottom {
        .container {
            height: 100%; padding-right: 170px;              
            @include boxSizing(border-box);
        } 
        height: 60px; background:#131211; 
        .logoFoot {
            float: left; width: 128px; height: 34px; margin: 12px 0 0 0;    
            background:url('../images/logo_foot.png') no-repeat 0 0;  
        }
        .copyright {
            position: absolute; width: 300px; 
            top: 50%; left: 40%; color: #999;
            @include transform(translate(-40%,-50%));             
        }  
        .logoMember {float: right; width: 206px; height: 36px; margin: 12px 0 0 0;}    
    }
}




.page-specialoffer {    
    .floating {
        position: fixed; width: 100%; height: 70px; background:#f58220; left: 0; bottom: 0;
        z-index: 5555; font-size: 25px; color: #fff;  
        background: rgba(245,131,32,1); 
        background: -moz-linear-gradient(-45deg, rgba(245,131,32,1) 0%, rgba(245,131,32,1) 35%, rgba(245,180,0,1) 65%, rgba(245,180,0,1) 100%);
        background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(245,131,32,1)), color-stop(35%, rgba(245,131,32,1)), color-stop(65%, rgba(245,180,0,1)), color-stop(100%, rgba(245,180,0,1)));
        background: -webkit-linear-gradient(-45deg, rgba(245,131,32,1) 0%, rgba(245,131,32,1) 35%, rgba(245,180,0,1) 65%, rgba(245,180,0,1) 100%);
        background: -o-linear-gradient(-45deg, rgba(245,131,32,1) 0%, rgba(245,131,32,1) 35%, rgba(245,180,0,1) 65%, rgba(245,180,0,1) 100%);
        background: -ms-linear-gradient(-45deg, rgba(245,131,32,1) 0%, rgba(245,131,32,1) 35%, rgba(245,180,0,1) 65%, rgba(245,180,0,1) 100%);
        .container {  
            padding: 11px 170px 0 0;            
            @include boxSizing(border-box);
            .fl {margin-top: 10px;}  
        }
    }
    footer {padding-bottom: 70px;}    
}


/* .wrap-jivochat {
    position: fixed; width: 303px; bottom: 0; right: 30px; 
    z-index: 6535;
} */





